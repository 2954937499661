<template>
	<el-dialog
	    :visible.sync="visible"
	    :close-on-click-modal="false"
	    title="小组已删除，切换小组"
	    width="439px">
	        <div class="tips">请选择进入一个小组</div>
			<div class="groupList">
				<template v-for="v in userInfo ? userInfo.groups : []">
					<div
						:key="v.groupId"
						:class="{ active: v.groupId === curGroupId }" 	
						class="group flex jc_b pointer not-select"
						@click="chooseGroup(v.groupId)">
						<div class="groupName">{{ v.groupName }}</div>
						<div class="groupBtn">切换</div>
					</div>
				</template>				
			</div>
	</el-dialog>
</template>

<script>
	import { mapMutations } from 'vuex'
	export default{
		name : "switchGroup",
		props : {
		    groupId : {            //小组信息
		        type : String,
		        default : ""
		    }
		},
		data() {
		    return {
		        visible: false,
		    };
		},
		mounted(){
			 const { userInfo, curGroupId } = this
		},
		methods:{
			...mapMutations(['SETCUR']),
			chooseGroup(groupId){
				this.$api.system.chooseGroup({
				    groupId:groupId
				}).then(res => {
					this.visible = false
				    this.SETCUR(groupId)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.groupList{
		margin: 20px 0 0;
		width: 391px;
		background: rgba(247,249,251,0.3);
		border-radius: 8px;
		border: 1px solid #E7ECF1;
		padding: 16px;
		.group{
			display: flex;
			&:not(:first-child){
				margin-top: 12px;
			}
			&.active{
				.groupName{
					color: $primary_text;
				}
				.groupBtn{
					background: #006AFF;
					color: #ffffff;
				}
			}
			.groupName{
				width: 200px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #888888;
				line-height: 20px;
			}
			.groupBtn{
				width: 44px;
				height: 20px;
				line-height: 20px;
				background: rgba(195,207,217,0.15);
				border-radius: 17px;
				font-size: 12px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #9AAAB7;
				text-align: center;
			}
		}
	}
	.tips{
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9AAAB7;
		line-height: 20px;
		position: absolute;
		top: 0;
	}
</style>
<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="439px"
    top="30vh"
    title="添加组员">
    <el-form
      :model="params"
      :rules="rules"
      ref="form"
      class="t-form"
      label-width="84px"
      label-position="left"
      size="medium"
      hide-required-asterisk
      @submit.native.prevent>
      <el-form-item  label="" label-width="0" prop="phone">
        <el-input
          v-model="params.phone"
          maxlength="11"
          oninput="value=value.replace(/[^\d]/g,'')"
          @keyup.enter.native="commit"
          class="input-width"
          placeholder="请填写新成员的手机号码"/>
      </el-form-item>
    </el-form>
    <div class="flex jc_e ai_c pt20">
      <div class="add-tip">
        *邀请后链接有效期2天
      </div>
	  <div class="flex">
		  <t-btn @click="visible = false" w="78px" h="36px" fs="14px" radius="8px" class="bold" type="info" >取消</t-btn>
		  <t-btn @click="commit" w="78px" h="36px" fs="14px" radius="8px" class="bold ml10">确认</t-btn>
	  </div>
    </div>
  </el-dialog>
</template>

<script>
import { isMobile } from '@/assets/javascript/util'

export default {
  data() {
    const phone = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      params: {
        phone: '',
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号码' },
          { validator: phone }
        ]
      },
    }
  },
  methods: {
    test(){
      console.log(111)
    },
    // 确认
    commit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('commit', this.params)
        }
      })
    },
    // 显示
    show() {
      this.visible = true
    },
    // 关闭重置
    closed() {
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style scoped lang="scss">
    .add-tip {
        flex: 1;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #60707D;
        line-height: 21px;
    }
</style>

<template>
    <el-dialog
        :visible.sync="visible"
        :close-on-click-modal="false"
        title="新建小组"
        top="30vh"
        width="439px">
            <el-form
                :model="params"
                :rules="rules"
                ref="form"
                class="t-form"
                label-width="100px"
                label-position="left"
				@submit.native.prevent
                size="medium">
                <el-form-item prop="groupName" label="" label-width="0">
                    <el-input
                        v-model="params.groupName"
                        class="input-width"
                        placeholder="请输入小组名称"/>
                </el-form-item>
                <el-form-item label="" label-width="0">
                    <el-checkbox v-model="toGroupAfterAdd">跳转小组进行管理</el-checkbox>
                </el-form-item>
            </el-form>
        <div class="flex jc_e ai_c pt20">
            <t-btn @click="visible = false" w="78px" h="36px" fs="14px" radius="8px" class="bold" type="info" >取消</t-btn>
            <t-btn @click="commit" w="78px" h="36px" fs="14px" radius="8px" class="bold ml10">确认</t-btn>
        </div>
    </el-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    name : "newGroup",
    props : {
        groupId : {            //小组信息
            type : String,
            default : ""
        }
    },
    data() {
        return {
            visible: false,
            toGroupAfterAdd: true,
            params: {
                groupName: '',
            },
            rules: {
                groupName: [{ required: true, message: "请输入小组名称" }],
            }
        };
    },
    methods: {
        ...mapMutations(['SETCUR','SETMSGDIALOG','SETUSERINFO']),
        // 确认
        async commit() {
            let params = this.params;
            let groupId;
            await this.$api.team.addNewGroup({
                ...params,
            }).then(res=>{
                groupId = res.groupId
                this.visible = false;
                this.$api.system.getUserInfo().then(res => {
                  this.SETUSERINFO(res)
                  this.$refs.switchGroup.visible = true
                  this.SETMSGDIALOG(null)
                })
                this.$emit("done")
            })

            if( this.toGroupAfterAdd ){
                this.$api.system.chooseGroup({
                    groupId
                }).then(res => {
                    this.SETCUR(groupId)
                })
            }
        },
        // 显示
        show(name) {
            this.visible = true;
            this.params.groupName = name;
        },
    }
};
</script>

<style lang="scss" scoped>
    .el-form .el-form-item {
        margin-bottom: 10px;
    }
</style>

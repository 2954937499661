<template>
  <div class="team-container">
    <div class="team-hd flex jc_b ai_c">
      <div v-for="(v, i) in stateList" :key="i" class="state-item flex jc_b ai_c">
        <div class="flex ai_c">
          <img :src="v.icon" width="28px" draggable=false />
          <span class="fs16 ml10">{{ v.label }}</span>
        </div>
        <span class="fs30 bold din-font" style="letter-spacing: -2px">
          {{ v.key === 'useCount' ? `${info[v.key] || 0}/${info.totalCount || 0}` : info[v.key] || 0 }}
        </span>
      </div>
      <t-btn @click="increase" :class="{ 'hidden': !curGroup.admin }" w="268px" h="74px" fs="18px" class="bold"
        isShadow><i class="add-icon" />添加组员</t-btn>
     
      <!-- <t-btn
            @click="onAddQuota"
            :class="{ 'hidden': !curGroup.admin }"
            type="danger"
            w="300px"
            h="74px"
            fs="18px"
            class="bold"
            isShadow><i class="add-icon" />增加名额</t-btn> -->
      <!-- <t-btn @click="addGroup" :class="{ 'hidden': !curGroup.admin }" type="danger" w="268px" h="74px" fs="18px"
        class="bold" isShadow><i class="add-icon" />新建小组</t-btn> -->
        <t-btn @click="addGroup"  type="danger" w="268px" h="74px" fs="18px"
        class="bold" isShadow><i class="add-icon" />新建小组</t-btn>
    </div>
    <div class="table-box">
      <div class="group-title flex jc_b">
        <div class="flex ai_s jc_c">
          <div class="name">{{ info.name }}</div>
          <t-btn w="106px" h="36px" fs="14px" radius="8px" type="success" plain :class="{ hidden: !curGroup.admin }"
            @click="renameShow">
            修改小组名称
          </t-btn>
          <!-- <el-tooltip :class="{ 'hidden': !curGroup.admin }" 
		  class="item" effect="dark" content="修改小组名称" placement="bottom">
			<i class="edit-icon" @click="renameShow"/>
		  </el-tooltip> -->
        </div>
        <t-btn w="106px" h="36px" fs="14px" type="warning" plain :class="{ hidden: !curGroup.admin }"
          @click="deleteGroup">删除小组</t-btn>
        <!-- <t-btn
          @click="renewAll"
          w="auto"
          h="auto"
          fs="16px"
          style="padding: 7px 15px;"
          plain>一键全部续费（{{ info.totalCount }}位）</t-btn> -->
      </div>
      <t-pagination @currentChange="currentChange" @sizeChange="sizeChange" :pages="pages">
        <el-table :data="list" slot="table" class="t-tabel" style="width: 100%" border>
          <el-table-column label="编号" width="80">
            <template slot-scope="scope">
              <span>{{ (scope.$index + 1) + (pageParams.page - 1) * pageParams.perPage }}</span>
            </template>
          </el-table-column>
          <el-table-column v-for="v in labelList" :key="v.key" :label="v.label" :min-width="v.width" :prop="v.key"
            :sortable="v.sortable">
            <template slot-scope="scope">
              <span v-if="v.key === 'admin'">
                <span v-if="scope.row.zhStatus !== '正常'">{{ scope.row.zhStatus }}</span>
                <span v-else>{{ roleList[scope.row.admin] }}</span>
              </span>
              <span v-else>{{ scope.row[v.key] || NOT }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="curGroup.admin" label="操作" width="280">
            <template v-if="scope.row.status === 1" slot-scope="scope">
              <!-- <t-btn
                @click="renewPersonal(scope.row.assignId)"
                class="mr24"
                type="primary"
                isText>
                续费
              </t-btn> -->
              <template v-if="scope.row.phone !== userInfo.phone">
                <t-btn @click="removeManager(scope.row.assignId)" v-if="scope.row.admin" class="mr24" type="danger"
                  isText>
                  移除管理员
                </t-btn>
                <t-btn @click="setManager(scope.row.assignId)" v-else class="mr24" type="primary" fs="14px" isText>
                  设为管理员
                </t-btn>
                <t-btn @click="removeUser(scope.row.assignId)" type="info" fs="14px" isText>
                  移除成员
                </t-btn>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </t-pagination>
    </div>

    <!-- 添加弹框 -->
    <increase @commit="increaseCommit" ref="increase" />

    <!-- 重命名弹框 -->
    <rename ref="rename" :groupId="info.groupId" @done="init"></rename>

    <!-- 新建小组 -->
    <new-group ref="newGroup" @done="init"></new-group>

    <!-- 切换小组 -->
    <switch-group ref="switchGroup" @done="init"></switch-group>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import pagination from '@/mixins/pagination'
import message from '@/mixins/message'
import increase from './components/increase';
import rename from "./components/rename";
import NewGroup from "./components/newGroup";
import SwitchGroup from "./components/switchGroup"

export default {
  mixins: [pagination, message],
  components: {
    increase,
    rename,
    NewGroup,
    SwitchGroup
  },
  data() {
    return {
      stateList: [
        {
          label: '全部组员',
          icon: require('@/assets/image/team/img_zuyuan_all.png'),
          key: 'totalCount'
        }, {
          label: '管理员',
          icon: require('@/assets/image/team/img_zuyuan_manager.png'),
          key: 'adminCount'
        },
        // {
        //   label: '名额使用情况',
        //   icon: require('@/assets/image/team/img_zuyuan_yiyong.png'),
        //   key: 'useCount',
        // }
      ],
      labelList: [{
        label: '姓名',
        width: '120',
        key: 'name'
      }, {
        label: '手机号码',
        width: '150',
        key: 'phone'
      }, {
        label: '角色',
        width: '120',
        key: 'admin'
      }, {
        label: '激活时间',
        width: '150',
        key: 'activeDate',
        sortable: true
      },
        // {
        //   label: '剩余到期天数',
        //   width: '130',
        //   key: 'rest'
        // }
      ],
      roleList: ['普通组员', '管理员'],
      info: {}
    }
  },
  watch: {
    curGroupId(val) {
      val && this.init()
    }
  },
  mounted() {
    const { userInfo, curGroupId } = this

  },
  methods: {
    ...mapMutations(['SETMSGDIALOG', 'SETUSERINFO', 'SETCUR']),
    // init
    init() {
      if (!this.curGroupId) {
        let groups = this.userInfo.groups
        const groupId = groups && groups[0].groupId;
        this.SETCUR(groupId)
      }
      this.getList()
      this.getGroupInfo()
    },
    // 点击添加组员
    increase() {
      this.$refs.increase.show()
    },
    // 获取小组成员列表
    getList() {
      const { curGroup, pageParams } = this

      this.$api.team.getGroupList({
        ...pageParams,
        groupId: curGroup.groupId
      }).then(res => {
        this.list = res.items || []
        this.pages = res.page || null
      })
    },
    // 小组信息
    getGroupInfo() {
      const { curGroup } = this

      this.$api.team.getGroupInfo({
        groupId: curGroup.groupId
      }).then(res => {
        this.info = res || {}
      })
    },
    // 添加组员
    increaseCommit(params) {
      const { curGroup } = this
      this.$api.team.inviteUser({
        admin: 0,
        ...params,
        groupId: curGroup.groupId
      }).then(res => {
        this.success({
          class: "column",
          message: '发送成功',
          tip: "邀请发送成功，用户需要通过点击收到的链接",
          duration: 1000
        })
        this.init()
        this.$refs.increase.visible = false
      })

      // this.$api.team.increaseUser({
      //   ...params,
      //   admin: 0,
      //   groupId: curGroup.groupId
      // }).then(res => {
      //   this.success({
      //    	 message: '添加成功',
      // duration:1000
      //   })
      //   this.init()
      //   this.$refs.increase.visible = false
      // })
    },

    // 显示小组重命名弹框
    renameShow() {
      this.$refs.rename.show(this.info.name)
    },

    // 设置管理员
    setManager(assignId) {
      this.SETMSGDIALOG({
        title: '设置管理员',
        message: '是否设置该成员为管理员，将拥有该小组所有权限',
        width: '439px',
        type: 'error',
        commit: () => {
          this.$api.team.setManager({
            assignId
          }).then(res => {
            this.success({
              message: '设置管理员成功',
              duration: 1000
            })
            this.init()
            this.SETMSGDIALOG(null)
          })
        }
      })
    },
    // 移除管理员
    removeManager(assignId) {
      this.SETMSGDIALOG({
        title: '移除管理员',
        message: '是否移除该成员的管理员',
        width: '439px',
        type: 'error',
        commit: () => {
          this.$api.team.setManager({
            assignId
          }).then(res => {
            this.success({
              message: '设置管理员成功',
              duration: 1000
            })
            this.init()
            this.SETMSGDIALOG(null)
          })
        }
      })
    },
    //删除小组
    deleteGroup() {
      const _this = this
      let message = `确认是否删除 <span style="color:#F4A93D">${_this.info.name}</span> 小组？`
      let groupsLen = this.userInfo && this.userInfo.groups.length || 0
      let tip = null
      if (groupsLen < 2) {
        message = `不可删除 <span style="color:#F4A93D">${_this.info.name}</span> 小组，`
        tip = '必须保留至少一个小组'
      }
      this.SETMSGDIALOG({
        title: '删除小组',
        message: message,
        width: '439px',
        type: 'error',
        tip: tip,
        commit: () => {
          _this.SETMSGDIALOG({
            title: '删除小组',
            message: `<span style="color:#F4A93D">删除后无法恢复，请谨慎操作！</span>`,
            width: '439px',
            type: 'error',
            commit: () => {
              this.SETMSGDIALOG(null)
              this.$api.team.deleteGroup({
                groupId: this.curGroupId
              }).then(res => {
                this.SETCUR(null)
                this.$api.system.getUserInfo().then(res => {
                  this.SETUSERINFO(res)
                  this.$refs.switchGroup.visible = true
                  this.SETMSGDIALOG(null)
                })
              }).catch(err => {

              })
            }
          })
        }
      })

    },
    // 移除成员
    removeUser(assignId) {
      this.SETMSGDIALOG({
        title: '移除成员',
        message: '是否移除该成员，将无法登陆该小组',
        width: '439px',
        type: 'error',
        commit: () => {
          this.$api.team.removeUser({
            assignId
          }).then(res => {
            this.success({
              message: '移除成员成功',
              duration: 1000
            })
            this.init()
            this.SETMSGDIALOG(null)
          })
        }
      })
    },
    // 添加名额
    onAddQuota() {
      const { curGroup } = this

      this.$router.push({
        name: 'addQuota',
        query: {
          id: curGroup.groupId
        }
      })
    },
    // 一键续费
    renewAll() {
      const { info: { totalCount } } = this
      const { curGroup } = this

      this.$router.push({
        name: 'renewAll',
        query: {
          id: curGroup.groupId,
          count: totalCount
        }
      })
    },
    // 一键续费
    renewPersonal(id) {
      this.$router.push({
        name: 'renewPersonal',
        query: {
          id,
        }
      })
    },
    // 新建小组
    addGroup() {
      this.$refs.newGroup.show();
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.team-container {
  height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 16px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  .team-hd {
    .state-item {
      width: 292px;
      height: 74px;
      background: #FFFFFF;
      box-shadow: 0px 2px 15px 0px #F4F6F7;
      border-radius: 8px;
      padding: 0 40px 0 22px;
    }

    .add-icon {
      width: 16px;
      height: 18px;
      background: url('../../assets/image/global/icon_add_white.png') no-repeat;
      background-size: 100%;
      margin-right: 7px;
    }
  }

  .table-box {
    padding: 36px 0 0;

    .group-title {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #383D41;
      line-height: 36px;
      margin-bottom: 16px;

      .name {
        margin-right: 14px;
      }

      .edit-icon {
        display: inline-block;
        cursor: pointer;
        margin-left: 10px;
        width: 18px;
        height: 18px;
        background: url('../../assets/image/team/xiaozu_edit_off.png') no-repeat;
        background-size: 100%;
        margin-right: 7px;
        transform: translateY(4px);

        &:hover {
          background: url('../../assets/image/team/xiaozu_edit_on.png') no-repeat;
        }
      }
    }
  }
}
</style>

<template>
    <el-dialog
        :visible.sync="visible"
        :close-on-click-modal="false"
        title="修改小组名称"
        width="439px">
            <el-form
                :model="params"
                :rules="rules"
                ref="form"
                class="t-form"
                label-width="100px"
                label-position="left"
                size="medium">
                <el-form-item prop="groupName" label="" label-width="0">
                    <el-input
                        v-model="params.groupName"
                        class="input-width"
						maxlength="20"
						show-word-limit
                        placeholder="请输入小组名称"/>
                </el-form-item>
            </el-form>
        <div class="flex jc_e ai_c pt20">
            <t-btn @click="visible = false" w="78px" h="36px" fs="14px" radius="8px" class="bold" type="info" >取消</t-btn>
            <t-btn @click="commit" w="78px" h="36px" fs="14px" radius="8px" class="bold ml10">确认</t-btn>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name : "groupRename",
    props : {
        groupId : {            //小组信息
            type : String,
            default : ""
        }
    },
    data() {
        return {
            visible: false,
            params: {
                groupName: '',
            },
            rules: {
                groupName: [{ required: true, message: "请输入小组名称" }],
            }
        };
    },
    methods: {
        // 确认
        commit() {
            let groupId = this.groupId;
            let params = this.params;
            this.$api.team.setGroupName({
                ...params,
                groupId
            }).then(res=>{
                this.visible = false;
                this.$emit("done")
            })
        },
        // 显示
        show(name) {
            this.visible = true;
            this.params.groupName = name;
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
